.app-info 
    padding: 25px
    background-color: #3d5a80
    border-radius: 4px
    box-shadow: 15px 15px 30px rgba(0,0,0, .15)
    color: #fff


@media (max-width: 768px) 
    h1 
        font-size: 24px   
    h2 
        font-size: 22px
    

@media (max-width: 576px) 
    h1 
        font-size: 20px    
    h2 
        font-size: 15px
.app-add-form 
    margin-top: 30px
    padding: 25px
    background-color: #3d5a80
    border-radius: 4px
    box-shadow: 15px 15px 30px rgba(0, 0, 0, .15)
    color: #fff

.add-form 
    margin-top: 20px
    input 
        width: 350px
        margin-right: 20px
    

@media (max-width: 768px)
    .add-form 
        input 
            min-width: 145px

@media (max-width: 576px)
    .app-add-form
        h3
            font-size: 20px

        .add-form.d-flex
            flex-direction: column

        .add-form 
            input 
                width: 100%
                margin-bottom: 15px
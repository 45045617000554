.btn-group 
    margin-top: 20px


@media (max-width: 768px) 
    .btn 
        font-size: 16px
    


@media (max-width: 576px) 
    .btn-group 
        display: flex
        flex-direction: column
.app-list 
    margin-top: 30px
    background-color: #3d5a80
    border-radius: 4px
    box-shadow: 15px 15px 30px rgba(0, 0, 0, .15)
    color: #fff

h4
    color: #3d5a80
    text-align: center
    padding-top: 15px